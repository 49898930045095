import type { ButtonProps as AntButtonProps } from 'antd/es/button/button'
import type { TooltipPlacement } from 'antd/es/tooltip'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import type { FC } from 'react'
import { memo } from 'react'
import styled, { useTheme } from 'styled-components'

import { useAppSelector } from '@redux/hooks'
import { selectIsPageInteraction } from '@reduxCommonApp/commonApp.selectors'

import Button from '@base/buttons/Button'
import TicketIcon from '@iconscout/unicons/svg/line/ticket.svg'
import type { ThemeColorsType } from '@themes/themes'

const MyTripsPopupHOC = dynamic(() => import('@components/myTrips/MyTripsPopup'))

type Props = {
  buttonType?: AntButtonProps['type']
  iconColorHover?: ThemeColorsType
  placementMenu?: TooltipPlacement
  showText?: boolean
}

const TicketsButton: FC<Props> = ({ buttonType = 'link', iconColorHover = 'link', placementMenu, showText = true }) => {
  const isPageInteraction = useAppSelector(selectIsPageInteraction)
  const { t } = useTranslation('common')
  const theme = useTheme()

  return (
    <ButtonStyled
      iconColor={theme.colors.icon as ThemeColorsType}
      iconColorHover={iconColorHover as ThemeColorsType}
      type={buttonType}
    >
      <TicketIcon />
      {showText && t('tickets')}
      {isPageInteraction && (
        <MyTripsPopupHOC placement={placementMenu}>
          <div style={{ inset: 0, position: 'absolute' }} />
        </MyTripsPopupHOC>
      )}
    </ButtonStyled>
  )
}

const ButtonStyled = styled(Button)`
  &.ant-btn.ant-btn-link svg {
    display: flex;
    margin-inline-end: 12px;

    path {
      fill: ${p => p.theme.colors.icon};
    }
  }
`

export default memo(TicketsButton)
